import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image-es5"
import CrossedArm from "../../../images/crossed-arm.png"
import Since1992 from "../../../images/since1992.jpg"
import TableIcon from "../../../images/icon-campaign-2.png"
import Tick from "../../../images/icon-campaign-3.png"
import Coins from "../../../images/icon-campaign-4.png"
import ArrowRight from "../../../images/icon-campaign-5.png"
import "./hero-banner-campaign.scss"

export const HeroBannerCampaign = ({
  title,
  subtitle,
  description,
  backgroundImage,
  callToAction,
}) => {
  const { hero } = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "section-page-banner-short.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const [counter, setCounter] = useState(1);
  const [pause, setPause] = useState(false);
  const handleNext = () => {
    if (counter !== chatboxV2.length) {
      setCounter(counter + 1);
    } else {
      setCounter(1);
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (!pause) {
        handleNext();
      } else {
        clearInterval(interval);
      }
    }, 8000);
    return () => clearInterval(interval);
  });

  const chatboxV2 = [
    {
      title: "I can only be very grateful that your services are out there and available to people like myself.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "Your service from start to finish has been amazing and the peace of mind it gave me allowed me to focus on getting debt free which I have now achieved.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "Business Debtline were thorough, informative, explained all options clearly to me, non judgmental, and made me feel there were solutions to my issues.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "I appreciated the patience and knowledge shown and shared with me.  I would highly recommend Business Debtline to anyone experiencing financial difficulties.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "Business Debtline gave me a way out to sort something I didn't feel in control of - I feel very grateful.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "Contact with Business Debtline helped me to take back control of my finances.  I'm working my way towards being debt free.  I sleep much better at night.",
      subtitle: "Business Debtline caller, 2023"
    },
  ];

  return (
<>
<div className="row">
        <div className="col-12 col-lg-12">
          <div className="row d-flex h-100">
            <div className="col-md-8 col-12 mb-4">
              <div className="card green-border" style={{ borderRadius : "20px" }}>
                <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between">
                  <div className="card-body-content">
                    <h2 className="h4 mb-3" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430" }}>We are Business Debtline - and we can help</h2>
                    <div className="break-down" >
                      <div className="icon-break-down">
                        <img src={Since1992} alt="Since1992" width="100%"/>
                      </div>
                      <div className="" >
                      <p>Business Debtline is a registered charity. Our advisers provide free, impartial, expert debt advice to small business owners and the self-employed across England, Wales and Scotland.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex" style={{ marginTop: "20px"}}>
                <div className="col-md-12 col-12 mb-4">
                  <div className="card green-border" style={{ border : "4px solid #573085", borderRadius: "20px" }}>
                    <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between align-center">
                      <div className="card-body-content justify-content-sm-between break-down">
                       <div className="click-here" style={{ display: "flex", justifyContent:"center", alignItems: "center" }} >
                        <a className="lead btn-lg d-inline-block hover-effect-btn"
                          href="https://businessdebtline.org/my-budget/"
                          role="button"
                          style={{ fontFamily: "FilsonProRegular", textDecoration: "none", color: "#FFFFFF", borderRadius: "15px", backgroundColor:"#573085",  textAlign: "center", width: "100%"  }}
                        >
                          Register/sign in
                        </a>
                        </div> 
                        <h2 className="h4 side-register" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430",  marginTop: "8px" }}>Use My Budget now or sign back in to your account.</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 mb-4 d-flex">
              {
                chatboxV2.map((box, index) => (
                  <div key={index} className={counter - 1 === index ? "show-campaign" : "not-show-campaign"} style={{ borderRadius: "11px", position: "relative" }} >
                    <div className="p-0 hero-banner-campaign bubble2" style={{ position: "relative" }}>
                      <div className="p-3" style={{ backgroundColor: "#0c376b", width: "100%", borderRadius: "20px" }}>
                        <p className="campaign2-title">{box.title}</p>
                        <div className="sub-bubble2" >
                          <div style={{ fontFamily: "Filsonsoft" }}>{box.subtitle}</div>
                        </div>
                      </div>
                    </div>

                    <div className="sub-bubble-mobile-wrapper">
                      <div className="wrap-sub-bubble-small">
                        <div className="sub-bubble-mobile2">
                          <h2 style={{ color: "#FFFFFF", fontFamily: "Filsonsoft" }}>{box.subtitle}</h2>
                        </div>
                      </div>
                    </div>
                  </div>

                ))
              }
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12">
          <div className="row d-flex h-100">
            <div className="col-md-4 col-12 mb-4 d-flex">
              <div className="card image-mobile" style={{ borderRadius : "20px" }}>             
                <img className="image-cropped-transform" src={CrossedArm} alt="crossed-arm" />               
              </div>
            </div>
            <div className="col-md-8 col-12 mb-4 d-flex">
              <div className="card green-border" style={{ borderRadius : "20px" }}>
                <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between">
                  <div className="card-body-content">
                    <h2 className="h4 mb-3" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430" }}>Get started today</h2>                    
                      <p>My Budget is designed by our experts especially for self-employed people and small business owners.</p>
                      <p>Doing a budget is a positive and important step to start dealing with your debts.</p>                                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-12">
          <div className="row d-flex h-100">
            <div className="col-md-8 col-12 mb-4">
              <div className="card green-border" style={{ border : "4px solid #573085", borderRadius : "20px" }}>
                <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between">
                  <div className="card-body-content">
                    <h2 className="h4 mb-3" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430" }}>Using My Budget</h2>
                    <div className="no-break-down" >
                      <div className="icon-no-break-down">
                        <img src={Tick} alt="Tick" width="100%"/>
                      </div>
                      <div className="icon-no-break-down">
                        <img src={Coins} alt="Coins" width="100%"/>
                      </div>
                      <div className="icon-no-break-down">
                        <img src={ArrowRight} alt="ArrowRight" width="100%"/>
                      </div>
                    </div>
                    <p>Dealing with business debts can be complicated, but you don't need to do this alone.</p>
                    <p>We help over 37,000 people and business in different circumstances every year.</p>
                    <p>Creating a budget using our tool before you speak with us is the best way to prepare for a call. Our advisers will use the information in the budget to explain your options.</p>
                    <p>You will get your own personalised budget and you can securely save your information and go back to it as many times as you want to.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 mb-4 d-flex" >
              <div className="card" style={{ backgroundColor: "#f2f2f2", borderRadius: "20px", width: "100%"}} >
              <div className="card-body break-down d-sm-flex flex-sm-column justify-content-sm-between" >
                <div className="card-body-content wrap-subtext-mobile">
                  <div className="different-situation" style={{ fontFamily: "FilsonProRegular"}}>Everyone has a different situation. If you're struggling with debt, <span style={{ fontFamily: "FilsonProBold", }}>start using our My Budget tool</span> now or <span style={{ fontFamily: "FilsonProBold", }}>sign back in to update your account.</span></div>
                </div>
                <div className="be-center">
                  <a
                    className="lead btn-lg d-inline-block hover-effect-btn"
                    href="https://businessdebtline.org/my-budget/"
                    role="button"
                    style={{ fontFamily: "FilsonProRegular", textDecoration:"none", borderRadius: "15px",  backgroundColor:"#573085", color:"#FFFFFF", width: "100%", textAlign:"center" }}
                  >
                   Register/sign in
                  </a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      


        <div className="col-12 col-lg-12">
          <div className="row d-flex h-100">
            <div className="col-md-6 col-12 mb-4 d-flex">
              <div className="card green-border" style={{ borderRadius : "20px" }} >
                <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between p-3">
                <div className="card-body-content ">
                  <h2 className="h4 mb-3" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430" }}>Speaking to an adviser</h2>
                    <ul className="purple-bullet">
                      <li>Once you've given us as much information as you can, you're ready to call our advisers. Don't worry if there are any gaps, our advisers can still help you.</li>
                      <li><strong>Call us on 0800 197 6026 Monday to Friday 9am-8pm or webchat with an adviser Monday to Friday 9am-5pm</strong></li>
                    </ul>
                </div>
                <div className="break-down">             
                <a
                    className="lead btn-blue btn-lg d-inline-block"
                    href="tel:0800 197 6026"
                    role="button"
                    style={{ fontFamily: "FilsonProRegular", color: "#FFFFFF", textDecoration:"none", borderRadius: "15px", padding: ".5rem 2rem", marginRight: "20px", marginBottom: "10px" }}
                  >
                    Call us
                  </a>

                  <a
                    className="lead btn-blue btn-lg d-inline-block"
                    href="https://www.businessdebtline.org/#webchat"
                    role="button"
                    style={{ fontFamily: "FilsonProRegular", color: "#FFFFFF", textDecoration:"none", borderRadius: "15px", padding: ".5rem 2rem", marginBottom: "10px" }}
                  >
                    Webchat
                  </a>
                  </div> 
                </div>  
              </div>
            </div>
            <div className="col-md-6 col-12 mb-4">
            <div className="card green-border" style={{ borderRadius : "20px" }}>
                <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between p-3">
                  <div className="card-body-content">
                  <h2 className="h4 mb-3" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430" }}>Dealing with your debts can give you a fresh start</h2>
                  <div className="break-down" >
                      <div className="icon-bottom" >
                        <img src={TableIcon} alt="star" width="100%" />
                      </div>
                      <div>
                        <p>Visiting our website is an important first step to dealing with your finances.</p>
                        <p>Around 19 million people in the UK could benefit from debt advice. Often it may seem difficult to deal with and we know that it can sometimes feel overwhelming.</p>
                        <p>Our advisers are trained to find the best solutions for everyone so call us today.</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</>
  )
}

HeroBannerCampaign.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  callToAction: PropTypes.shape({
    href: PropTypes.string,
    ariaLabel: PropTypes.string,
    label: PropTypes.string,
  }),
}
