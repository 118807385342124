import React from "react"

import { Link } from "gatsby"
import { observer, inject } from "mobx-react"

import { Wrapper } from "../components/base/wrapper/wrapper"
import { SEO } from "../components/base/seo/seo"
import { RegionToggle } from "../components/modules/region-toggle/region-toggle"

const MyBudget = inject("regionStore")(
  observer(({ regionStore }) => (
    <Wrapper>
      <SEO title="My Budget" description="My budget" />

      <div className="row">
        <div className="offset-2 col-8">
          <h1 className="mb-3">Not available</h1>

          <p>We're sorry, My Budget is currently unavailable. Please check back soon.</p>
          <p>If you need urgent advice, please call us on <a href="Tel:0800 197 6026" >0800 197 6026</a> or <a href="https://www.businessdebtline.org/#webchat" >webchat</a> with us. Webchat is open 9am to 5pm Monday to Friday and our phone service 9am to 8pm Monday to Friday.</p>
          <p>
            <strong>
              <Link to="/" aria-label="homepage">
                Click here
              </Link>
              &nbsp;to return to the homepage.
            </strong>
          </p>
        </div>
      </div>
    </Wrapper>
  ))
)

export default MyBudget
