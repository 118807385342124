import React from "react"

import { graphql } from "gatsby"

import { Wrapper } from "../../components/base/wrapper/wrapper"
import { SEO } from "../../components/base/seo/seo"
import { HeroBanner } from "../../components/modules/hero-banner/hero-banner"
import { HeroBannerCampaign } from "../../components/modules/hero-banner/hero-banner-campaign"
import { Alert } from "../../components/modules/alert/alert"
import { CardGrid } from "../../components/layout/card-grid/card-grid"

export default ({ data }) => {
  const {
    seoTitle,
    description,
    canonicalUrl,
    ctaButtonText,
    ctaButtonUrl,
    ctaDescription,
    ctaHeader,
    ctaButtonAriaLabel,
    ctaImage,
    alert,
    body,
  } = data.wagtail.page

  const findValueById = (blocks, id) =>
    blocks.find(block => block.id === id).rawValue

  return (
    <Wrapper hasFeedbackForm={true}>
      <SEO
        title={seoTitle}
        description={description}
        canonical={canonicalUrl}
      />

      {alert.map(({ blocks }) => {
        const title = findValueById(blocks, "title")
        const paragraph = findValueById(blocks, "paragraph")
        const link = findValueById(blocks, "link")
        const linkLabel = findValueById(blocks, "label")
        const linkAriaLabel = findValueById(blocks, "aria_label")

        return (
          <Alert
            key={title}
            title={title}
            description={paragraph}
            button={{
              href: link,
              label: linkLabel,
              ariaLabel: linkAriaLabel,
            }}
          />
        )
      })}

      {/* <HeroBanner
        title={ctaHeader}
        description={ctaDescription}
        backgroundImage={ctaImage}
        callToAction={{
          href: ctaButtonUrl,
          label: ctaButtonText,
          ariaLabel: ctaButtonAriaLabel,
        }}
      /> */}
      <HeroBannerCampaign />

      {/* <CardGrid body={body} /> */}
    </Wrapper>
  )
}

export const query = graphql`
  query($slug: String) {
    wagtail {
      page(slug: $slug) {
        ... on HomePage {
          seoTitle
          description
          canonicalUrl
          ctaButtonText
          ctaButtonUrl
          ctaDescription
          ctaHeader
          ctaButtonAriaLabel
          ctaImage {
            src
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          alert {
            ... on StructBlock {
              field
              blocks {
                ... on CharBlock {
                  id
                  rawValue
                }
                ... on URLBlock {
                  id
                  rawValue
                }
              }
            }
          }
          body {
            ... on StructBlock {
              blocks {
                ... on ChoiceBlock {
                  rawValue
                }
                ... on StreamBlock {
                  blocks {
                    ... on StructBlock {
                      field
                      blocks {
                        field
                        blockType
                        ... on CharBlock {
                          field
                          value
                        }
                        ... on RichTextBlock {
                          rawValue
                          field
                        }
                        ... on BooleanBlock {
                          rawValue
                          field
                        }
                        ... on ChoiceBlock {
                          value
                          field
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
